export const isInViewport = (el, partiallyVisible = true) => {
  const { top, left, bottom, right } = el.getBoundingClientRect();
  const { innerHeight, innerWidth } = window;
  return partiallyVisible
    ? ((top > 0 && top < innerHeight) ||
        (bottom > 0 && bottom < innerHeight)) &&
        ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
    : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
};

export const addAnimation = (container, cssClass, scrollPosition) => {
  const windowHeight = window.innerHeight;
  const elementTop = container.getBoundingClientRect().top;
  const elementVisible = scrollPosition ?? 200;

  if (elementTop < windowHeight - elementVisible) {
    container.classList.add(cssClass);
  }
};

export const addAnimationOnScroll = (container, cssClass, scrollPosition) => {
  const windowHeight = window.innerHeight;
  const elementTop = container.getBoundingClientRect().top;
  const elementVisible = scrollPosition ?? 200;

  if (elementTop < windowHeight - elementVisible) {
    container.classList.add(cssClass);
  }
};

export const addAnimationOnLoad = (element, cssClass, delay = 0) => {
  window.addEventListener("load", () => {
    setTimeout(() => {
      element.classList.add(cssClass);
    }, delay);
  });
};
