// import "./components/animations";
import {
  addAnimationOnLoad,
  addAnimationOnScroll,
  isInViewport,
} from "./components/functions";
import "./components/svg";

const html = document.querySelector("html");

if (html) {
  const changeFontsSize = () => {
    if (window.innerWidth < 1920) {
      html.style.fontSize = "62.5%";
    } else if (window.innerWidth >= 3840) {
      html.style.fontSize = "100%";
    } else if (window.innerWidth > 1920) {
      const step = 0.02953125;
      const fullHd = 1920;
      let percentages;
      percentages = (window.innerWidth - fullHd) * step + 62.5;
      html.style.fontSize = `${percentages}%`;
    }
  };
  window.addEventListener("resize", changeFontsSize);
  changeFontsSize();
}

document.onreadystatechange = () => {
  if (document.readyState === "complete") {
    const formControls = document.querySelectorAll(".wpcf7-form-control");
    const forms = document.querySelectorAll(".wpcf7-form");
    if (forms && formControls) {
      forms.forEach((el) => {
        el.removeAttribute("novalidate");
      });
      formControls.forEach((el) => {
        if (el.getAttribute("aria-required")) {
        }
        el.setAttribute("required", true);
      });
    }
  }
};

const menuBtn = document.querySelector(".menu-button");
const menuContainer = document.querySelector(".menu-container");
const navbarLang = document.querySelector(".navbar__languages");
const bottomMenu = document.querySelector(".menu-container__bottom-menu");
const body = document.querySelector("body");
const navbar = document.querySelector(".navbar");

if (menuBtn && menuContainer) {
  menuBtn.addEventListener("click", () => {
    if (menuBtn.classList.contains("menu-button--active")) {
      body.style.overflow = "";
      menuBtn.classList.remove("menu-button--active");
      menuContainer.classList.remove("menu-active");
      navbarLang.classList.remove("navbar__languages--active");
      bottomMenu.classList.remove("menu-container__bottom-menu--active");
      navbar.classList.remove("menu-active");
    } else {
      body.style.overflow = "hidden";
      menuBtn.classList.add("menu-button--active");
      menuContainer.classList.add("menu-active");
      navbarLang.classList.add("navbar__languages--active");
      bottomMenu.classList.add("menu-container__bottom-menu--active");
      navbar.classList.add("menu-active");
    }
  });
}

window.addEventListener("load", (event) => {
  if (navbar) {
    navbar.classList.remove("navbar--hidden");
  }
});

if (navbar) {
  const checkScrollPosition = () => {
    if (window.scrollY > 0) {
      navbar.classList.add("navbar--fixed");
      menuContainer.classList.add("menu-scrolled");
    } else {
      navbar.classList.remove("navbar--fixed");
      menuContainer.classList.remove("menu-scrolled");
    }
  };
  window.addEventListener("scroll", () => checkScrollPosition());
  checkScrollPosition();
}

const menuItemsWithSubmenu = document.querySelectorAll(
  ".menu-item-has-children"
);

if (menuItemsWithSubmenu) {
  for (let i = 0; i < menuItemsWithSubmenu.length; i++) {
    menuItemsWithSubmenu.forEach((el) =>
      el.classList.remove("menu-item-has-children--active")
    );
    menuItemsWithSubmenu[i].addEventListener("click", function () {
      menuItemsWithSubmenu.forEach((el) =>
        el.classList.remove("menu-item-has-children--active")
      );
      setTimeout(() => {
        menuItemsWithSubmenu[i].classList.add("menu-item-has-children--active");
      }, 500);
    });
  }
}

// const subPageHero = document.querySelector(".subpage-hero");

// if (subPageHero) {
//   const subPageHeroContent = subPageHero.querySelector(
//     ".subpage-hero__content"
//   );
//   if (subPageHeroContent) {
//     if (isInViewport(subPageHeroContent)) {
//       addAnimationOnLoad(subPageHeroContent, "active", 200);
//     } else {
//       window.addEventListener("scroll", () => {
//         addAnimationOnScroll(subPageHeroContent, "active", 200);
//       });
//     }
//   }
// }

const introSection = document.querySelector(".intro-section");

if (introSection) {
  const introSectionContentWrapper = introSection.querySelector(
    ".intro-section__content__wrapper"
  );
  const introSectionImage = introSection.querySelector(".intro-section__image");
  if (isInViewport(introSectionImage)) {
    addAnimationOnLoad(introSectionImage, "intro-section__image--active", 500);
  }
  if (isInViewport(introSectionContentWrapper)) {
    addAnimationOnLoad(introSectionContentWrapper, "active", 500);
  }

  window.addEventListener("scroll", () => {
    addAnimationOnScroll(introSectionContentWrapper, "active", 200);
    addAnimationOnScroll(
      introSectionImage,
      "intro-section__image--active",
      200
    );
  });
}

const callToAction = document.querySelector(".call-to-action-section");
if (callToAction) {
  if (isInViewport(callToAction)) {
    addAnimationOnLoad(callToAction, "active", 500);
  }
  window.addEventListener("scroll", () => {
    addAnimationOnScroll(callToAction, "active", 200);
  });
}

const textWithLink = document.querySelector(".text-with-link-section");

if (textWithLink) {
  if (isInViewport(textWithLink)) {
    addAnimationOnLoad(textWithLink, "active", 500);
  }
  window.addEventListener("scroll", () => {
    addAnimationOnScroll(textWithLink, "active", 200);
  });
}

const textWithImage = document.querySelector(".text-with-image-section");

if (textWithImage) {
  const textWithImageImage = textWithImage.querySelector(
    ".text-with-image-section__image"
  );
  const textWithImageContent = textWithImage.querySelector(
    ".text-with-image-section__content"
  );
  if (isInViewport(textWithImageImage)) {
    addAnimationOnLoad(textWithImageImage, "active", 500);
  }
  window.addEventListener("scroll", () => {
    addAnimationOnScroll(textWithImageImage, "active", 200);
  });
  if (isInViewport(textWithImageContent)) {
    addAnimationOnLoad(textWithImageContent, "active", 500);
  }
  window.addEventListener("scroll", () => {
    addAnimationOnScroll(textWithImageContent, "active", 200);
  });
}

const bonusSection = document.querySelector(".bonus-section");

if (bonusSection) {
  const textWithImageImage = bonusSection.querySelector(
    ".bonus-section__image"
  );
  const textWithImageContent = bonusSection.querySelector(
    ".bonus-section__content"
  );
  if (isInViewport(textWithImageImage)) {
    addAnimationOnLoad(textWithImageImage, "active", 500);
  }
  window.addEventListener("scroll", () => {
    addAnimationOnScroll(textWithImageImage, "active", 200);
  });
  if (isInViewport(textWithImageContent)) {
    addAnimationOnLoad(textWithImageContent, "active", 500);
  }
  window.addEventListener("scroll", () => {
    addAnimationOnScroll(textWithImageContent, "active", 200);
  });
}
