import anime from "animejs";

const svgDuration = 3000;

// animation of homepage svg paths
const homepageMeetUsSvg = document.querySelector(".meet-us-mask");
let homepageMeetUsSvgAnimated = false;
const homeMeetUsAnimationTime = svgDuration;

const animateHomepageMeetUs = () => {
  const targets = homepageMeetUsSvg.querySelectorAll("path");

  anime({
    targets,
    strokeDashoffset: [anime.setDashoffset, 0],
    easing: "linear",
    duration: homeMeetUsAnimationTime,
    delay: 0,
  });
};

window.addEventListener("scroll", () => {
  if (homepageMeetUsSvg && !homepageMeetUsSvgAnimated) {
    const windowHeight = window.innerHeight;
    const elementTop = homepageMeetUsSvg.getBoundingClientRect().top;
    const elementVisible = 200;
    if (elementTop < windowHeight - elementVisible) {
      animateHomepageMeetUs();
      homepageMeetUsSvgAnimated = true;
    }
  }
});

// animation of hero and intro section svg paths
const heroSvg = document.querySelector(".subpage-hero__mask");
let heroSvgAnimated = false;

const introSvg = document.querySelector(".intro-section__mask");
let introSvgAnimated = false;

const animateSvg = () => {
  const easing = "linear";
  const strokeDashoffset = [anime.setDashoffset, 0];

  // hero svg
  if (heroSvg) {
    const targets = heroSvg.querySelectorAll("path");

    const duration = svgDuration;
    const delay = 0;

    anime({
      targets,
      strokeDashoffset,
      easing,
      duration,
      delay,
    });

    heroSvgAnimated = true;
  }

  // intro svg
  if (introSvg) {
    const targets = introSvg.querySelectorAll("path");

    const duration = svgDuration;
    const delay = svgDuration * (2 / 3);

    anime({
      targets,
      strokeDashoffset,
      easing,
      duration,
      delay,
    });

    introSvgAnimated = true;
  }
};

const elementToEnter = heroSvg || introSvg;

const checkScrollPosition = () => {
  if (elementToEnter && !heroSvgAnimated) {
    const windowHeight = window.innerHeight;
    const elementTop = elementToEnter.getBoundingClientRect().top;
    const elementVisible = 200;
    if (elementTop < windowHeight - elementVisible) {
      animateSvg();
    }
  }
};

window.addEventListener("load", checkScrollPosition);
window.addEventListener("scroll", checkScrollPosition);

// animation of portfolio svg paths
const portfolioHeaderSvg = document.querySelector(".portfolio__header__mask");
let prtfolioHeaderSvgAnimated = false;
const portfolioImageSvg = document.querySelector(".portfolio__mask");
let portfolioImageSvgAnimated = false;
const portfolioGallerySvg = document.querySelector(
  ".single-portfolio-page__gallery__mask"
);
let portfolioGallerySvgAnimated = false;

const animatePortfolioSvg = () => {
  const easing = "linear";
  const strokeDashoffset = [anime.setDashoffset, 0];

  // portfolio header svg
  if (portfolioHeaderSvg) {
    const targets = portfolioHeaderSvg.querySelectorAll("path");

    const duration = svgDuration;
    const delay = 0;

    anime({
      targets,
      strokeDashoffset,
      easing,
      duration,
      delay,
    });

    prtfolioHeaderSvgAnimated = true;
  }

  // portfolio image svg
  if (portfolioImageSvg) {
    const targets = portfolioImageSvg.querySelectorAll("path");

    const duration = svgDuration;
    const delay = svgDuration * (10 / 27);

    anime({
      targets,
      strokeDashoffset,
      easing,
      duration,
      delay,
    });

    portfolioImageSvgAnimated = true;
  }

  // gallery svg
  if (portfolioGallerySvg && portfolioImageSvgAnimated) {
    const targets = portfolioGallerySvg.querySelectorAll("path");

    const duration = svgDuration;
    const delay = svgDuration * 1.1;

    anime({
      targets,
      strokeDashoffset,
      easing,
      duration,
      delay,
    });

    portfolioGallerySvgAnimated = true;
  }
};

window.addEventListener("scroll", () => {
  if (portfolioGallerySvg && !portfolioGallerySvgAnimated) {
    const windowHeight = window.innerHeight;
    const elementTop = portfolioGallerySvg.getBoundingClientRect().top;
    const elementVisible = 200;
    if (elementTop < windowHeight - elementVisible) {
      animatePortfolioSvg();
      portfolioGallerySvgAnimated = true;
    }
  }
});

window.addEventListener("load", animatePortfolioSvg);

// animation of contact svg paths
const contactSvg = document.querySelector(".contact-content__mask");
let contactSvgAnimated = false;

const animateContactSvg = () => {
  const easing = "linear";
  const strokeDashoffset = [anime.setDashoffset, 0];

  // portfolio image svg
  if (contactSvg) {
    const targets = contactSvg.querySelectorAll("path");

    const duration = svgDuration;
    const delay = 0;

    anime({
      targets,
      strokeDashoffset,
      easing,
      duration,
      delay,
    });

    contactSvgAnimated = true;
  }

  // contact content svg
  if (contactSvgAnimated) {
    const targets = contactSvg.querySelectorAll("path");

    const duration = svgDuration;
    const delay = svgDuration * (2 / 3);

    anime({
      targets,
      strokeDashoffset,
      easing,
      duration,
      delay,
    });

    contactSvgAnimated = true;
  }
};

window.addEventListener("scroll", () => {
  if (contactSvg && !contactSvgAnimated) {
    const windowHeight = window.innerHeight;
    const elementTop = contactSvg.getBoundingClientRect().top;
    const elementVisible = 200;
    if (elementTop < windowHeight - elementVisible) {
      animateContactSvg();
      contactSvgAnimated = true;
    }
  }
});

window.addEventListener("load", animateContactSvg);
